@charset "UTF-8";
.hero-image-commons .messages {
  position: absolute;
  top: 60px;
  width: 100%;
}
.hero-image-commons .messages .alert {
  text-align: center;
}
.hero-image-commons .messages .alert.alert-success {
  background: rgba(40, 182, 44, 0.6);
}
.hero-image-commons .breadcrumb {
  display: none;
}
.hero-image-commons .navbar.app-header .navbar-toggle {
  color: white;
}
.hero-image-commons .navbar.app-header .btn-underline {
  color: white;
}
.hero-image-commons .navbar.app-header .btn-underline:before {
  background: white;
}
.hero-image-commons .navbar.app-header .btn-underline:hover,
.hero-image-commons .navbar.app-header .btn-underline:active {
  color: black;
}
@media screen and (max-width: 767px) {
  .hero-image-commons .navbar.app-header h1 {
    font-size: 30px;
  }
  .hero-image-commons .navbar.app-header .navbar-collapse .btn-underline {
    color: black;
  }
}
select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(/public/images/dropdown_icon.png) right / 20px no-repeat #fff;
  padding-right: 20px;
}
.btn:hover {
  text-decoration: underline;
}
.btn-toolbar > .btn {
  margin-bottom: 5px;
}
.modal-body {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
}
.hero-image {
  background: no-repeat url('/public/images/Khp_header1.jpg') center top;
  background-size: cover;
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
}
.hero-image {
  height: 580px;
}
.hero-image + .container {
  height: 509px;
}
.hero-image + .container .jumbotron {
  border: 0;
  background: transparent;
  margin-top: 20px;
  margin-bottom: 0;
  box-shadow: none;
  color: white;
}
.hero-image + .container .jumbotron h1 {
  font-size: 48px;
  text-transform: uppercase;
}
.hero-image + .container .jumbotron p {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
}
.hero-image + .container .jumbotron img {
  max-width: 75px;
  display: inline-block;
}
@media screen and (max-width: 1024px) {
  .hero-image + .container .jumbotron h1 {
    font-size: 36px;
  }
}
@media screen and (max-width: 768px) {
  .hero-image + .container .jumbotron h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 400px) {
  .hero-image + .container .jumbotron h1 {
    font-size: 26px;
  }
}
@media screen and (max-width: 1024px) {
  .hero-image + .container .jumbotron p {
    font-size: 16px;
  }
}
@media screen and (max-width: 480px) {
  .hero-image + .container .jumbotron p {
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .hero-image + .container .jumbotron {
    margin-top: 0;
  }
}
@media screen and (max-width: 768px) {
  .hero-image + .container {
    height: 500px;
  }
}
body.about {
  color: black;
}
body.about .messages {
  position: absolute;
  top: 60px;
  width: 100%;
}
body.about .messages .alert {
  text-align: center;
}
body.about .messages .alert.alert-success {
  background: rgba(40, 182, 44, 0.6);
}
body.about .breadcrumb {
  display: none;
}
body.about .navbar.app-header .navbar-toggle {
  color: white;
}
body.about .navbar.app-header .btn-underline {
  color: white;
}
body.about .navbar.app-header .btn-underline:before {
  background: white;
}
body.about .navbar.app-header .btn-underline:hover,
body.about .navbar.app-header .btn-underline:active {
  color: black;
}
@media screen and (max-width: 767px) {
  body.about .navbar.app-header h1 {
    font-size: 30px;
  }
  body.about .navbar.app-header .navbar-collapse .btn-underline {
    color: black;
  }
}
body.about .navbar.app-header .navbar-brand img.light {
  display: block;
}
body.about .navbar.app-header .navbar-brand img.dark {
  display: none;
}
body.about .hero-image {
  background-image: url('/public/images/Khp_header2.jpg');
}
body.about h2 {
  margin: 0 0 15px 0;
}
body.about ol {
  padding-left: 20px;
}
body.about .col-md-4 img {
  margin-top: 10px;
  max-width: 220px;
}
body.about .compare {
  background: #cdeadf;
}
body.about .how-it-works {
  background: white;
}
body.about .real-science {
  background: #cfe2e9;
}
body.about .real-science .col-md-4 img {
  max-width: 100%;
  margin-top: 0;
}
body.about .compare .text,
body.about .how-it-works .text,
body.about .real-science .text {
  -webkit-columns: auto 2;
  -moz-columns: auto 2;
  columns: auto 2;
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px;
}
body.about .compare .text ol,
body.about .how-it-works .text ol,
body.about .real-science .text ol {
  float: left;
}
body.about .compare .text ol li,
body.about .how-it-works .text ol li,
body.about .real-science .text ol li {
  break-inside: avoid;
}
@media screen and (max-width: 480px) {
  body.about .compare .text,
  body.about .how-it-works .text,
  body.about .real-science .text {
    -webkit-columns: auto 1;
    -moz-columns: auto 1;
    columns: auto 1;
  }
}
@media screen and (max-width: 768px) {
  body.about .compare h2,
  body.about .how-it-works h2,
  body.about .real-science h2 {
    text-align: center;
    padding-top: 20px;
  }
}
body.about .publications {
  border-top: 1px solid black;
  padding-top: 30px;
  margin-top: 30px;
  margin-bottom: 60px;
}
body.about .publications h3 {
  margin: 0 0 15px 0;
}
body.about .publications .text {
  -webkit-columns: auto 3;
  -moz-columns: auto 3;
  columns: auto 3;
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px;
}
body.about .publications .text a {
  color: black;
}
body.about .publications .text ol {
  padding-left: 0;
}
body.about .publications .text ol li {
  margin-bottom: 5px;
}
@media screen and (max-width: 480px) {
  body.about .publications .text {
    -webkit-columns: auto 1;
    -moz-columns: auto 1;
    columns: auto 1;
  }
}
body.about .the-story img {
  max-height: 500px;
}
body.about .the-story img + small {
  text-align: center;
  display: block;
  max-width: 200px;
  margin: 5px auto;
}
body.data_protection,
body.pricing {
  color: black;
}
body.data_protection .messages,
body.pricing .messages {
  position: absolute;
  top: 60px;
  width: 100%;
}
body.data_protection .messages .alert,
body.pricing .messages .alert {
  text-align: center;
}
body.data_protection .messages .alert.alert-success,
body.pricing .messages .alert.alert-success {
  background: rgba(40, 182, 44, 0.6);
}
body.data_protection .breadcrumb,
body.pricing .breadcrumb {
  display: none;
}
body.data_protection .navbar.app-header .navbar-toggle,
body.pricing .navbar.app-header .navbar-toggle {
  color: white;
}
body.data_protection .navbar.app-header .btn-underline,
body.pricing .navbar.app-header .btn-underline {
  color: white;
}
body.data_protection .navbar.app-header .btn-underline:before,
body.pricing .navbar.app-header .btn-underline:before {
  background: white;
}
body.data_protection .navbar.app-header .btn-underline:hover,
body.pricing .navbar.app-header .btn-underline:hover,
body.data_protection .navbar.app-header .btn-underline:active,
body.pricing .navbar.app-header .btn-underline:active {
  color: black;
}
@media screen and (max-width: 767px) {
  body.data_protection .navbar.app-header h1,
  body.pricing .navbar.app-header h1 {
    font-size: 30px;
  }
  body.data_protection .navbar.app-header .navbar-collapse .btn-underline,
  body.pricing .navbar.app-header .navbar-collapse .btn-underline {
    color: black;
  }
}
body.data_protection .navbar.app-header .navbar-brand img.light,
body.pricing .navbar.app-header .navbar-brand img.light {
  display: block;
}
body.data_protection .navbar.app-header .navbar-brand img.dark,
body.pricing .navbar.app-header .navbar-brand img.dark {
  display: none;
}
body.data_protection .hero-image,
body.pricing .hero-image {
  background-image: url('/public/images/Khp_header2.jpg');
}
body.data_protection h2,
body.pricing h2 {
  margin: 0 0 15px 0;
}
body.data_protection ul,
body.pricing ul {
  padding-left: 20px;
}
body.data_protection .compare,
body.pricing .compare {
  background: #cdeadf;
}
body.data_protection .how-it-works,
body.pricing .how-it-works {
  background: white;
}
body.data_protection .real-science,
body.pricing .real-science {
  background: #cfe2e9;
}
@media screen and (max-width: 480px) {
  body.data_protection .compare .text,
  body.pricing .compare .text,
  body.data_protection .how-it-works .text,
  body.pricing .how-it-works .text,
  body.data_protection .real-science .text,
  body.pricing .real-science .text {
    -webkit-columns: auto 1;
    -moz-columns: auto 1;
    columns: auto 1;
  }
}
@media screen and (max-width: 768px) {
  body.data_protection .compare h2,
  body.pricing .compare h2,
  body.data_protection .how-it-works h2,
  body.pricing .how-it-works h2,
  body.data_protection .real-science h2,
  body.pricing .real-science h2 {
    text-align: center;
    padding-top: 20px;
  }
}
.data_protection .text p:first-child {
  margin-top: 0;
}
.data_protection .text p,
.data_protection .text ul {
  font-size: 16px;
  line-height: 1.5em;
  margin: 2em 0;
}
body.frontpage .messages {
  position: absolute;
  top: 60px;
  width: 100%;
}
body.frontpage .messages .alert {
  text-align: center;
}
body.frontpage .messages .alert.alert-success {
  background: rgba(40, 182, 44, 0.6);
}
body.frontpage .breadcrumb {
  display: none;
}
body.frontpage .navbar.app-header .navbar-toggle {
  color: white;
}
body.frontpage .navbar.app-header .btn-underline {
  color: white;
}
body.frontpage .navbar.app-header .btn-underline:before {
  background: white;
}
body.frontpage .navbar.app-header .btn-underline:hover,
body.frontpage .navbar.app-header .btn-underline:active {
  color: black;
}
@media screen and (max-width: 767px) {
  body.frontpage .navbar.app-header h1 {
    font-size: 30px;
  }
  body.frontpage .navbar.app-header .navbar-collapse .btn-underline {
    color: black;
  }
}
body.frontpage .navbar.app-header .navbar-brand {
  display: none;
}
body.frontpage h2 {
  margin-top: 0;
}
body.frontpage .why-use,
body.frontpage .cta-forms,
body.frontpage .call-to-action {
  padding: 60px 0;
}
@media screen and (max-width: 767px) {
  body.frontpage .why-use img,
  body.frontpage .cta-forms img,
  body.frontpage .call-to-action img {
    margin-top: 30px;
  }
  body.frontpage .why-use .btn-underline,
  body.frontpage .cta-forms .btn-underline,
  body.frontpage .call-to-action .btn-underline {
    margin-top: 30px;
  }
}
body.frontpage .why-use {
  color: black;
}
body.frontpage .why-use h2 {
  padding-bottom: 45px;
}
body.frontpage .why-use img {
  height: 100px;
  max-width: 180px;
  margin-bottom: 30px;
}
body.frontpage .why-use p {
  margin-top: 15px;
  text-align: center;
  min-height: 220px;
  font-size: 16px;
}
@media screen and (max-width: 990px) {
  body.frontpage .why-use p {
    min-height: 240px;
  }
}
@media screen and (max-width: 767px) {
  body.frontpage .why-use p {
    min-height: auto;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }
}
body.frontpage .cta-forms {
  background: #cdeadf;
}
body.frontpage .cta-forms h2,
body.frontpage .cta-forms p {
  margin-bottom: 30px;
}
body.frontpage .cta-forms h2 {
  padding-right: 0;
}
body.frontpage .cta-forms p {
  font-size: 16px;
}
body.frontpage .cta-forms h2,
body.frontpage .call-to-action h2 {
  font-size: 35px;
  color: #14945f;
  text-align: center;
}
body.frontpage .call-to-action h2 {
  padding: 0 65px 0 0;
}
body.frontpage .call-to-action p {
  color: black;
  padding: 0;
  max-width: 640px;
}
body.frontpage .call-to-action input[disabled] {
  background-color: #6f6f6f;
  border-color: #6f6f6f;
}
@media screen and (max-width: 767px) {
  body.frontpage .call-to-action h2,
  body.frontpage .call-to-action p {
    text-align: left;
    margin-bottom: 30px;
  }
}
body.new-form h1 {
  margin: 0;
}
body.new-form label span {
  font-size: 12px;
}
body.new-form .radio {
  padding: 10px;
}
body.new-form .radio input {
  margin-right: 10px;
}
body.new-form .radio:nth-child(even) {
  background: #f5f5f5;
}
body.new-form .question-areas-container > label {
  margin-top: 5px;
}
body.new-form .question-areas-container .question-areas label {
  display: block;
  padding: 15px;
  margin: 0;
  border-bottom: 1px solid #a9a9a9;
  line-height: 20px;
  font-weight: normal;
}
body.new-form .question-areas-container .question-areas label.mandatory {
  color: #a9a9a9;
}
body.new-form .question-areas-container .question-areas label input {
  margin-right: 10px;
}
body.new-form .question-areas-container .question-areas label span,
body.new-form .question-areas-container .question-areas label a {
  font-size: 100%;
  display: inline-block;
  margin: 0 10px;
  font-weight: 100;
}
body.new-form .question-areas-container .question-areas label .questions-list {
  margin: 15px 0;
}
body.new-form .question-areas-container .question-areas label .questions-list li {
  padding: 5px;
}
body.new-form .btn .glyphicon {
  margin-left: 10px;
}
body.new-form .button-group {
  padding-top: 30px;
}
body.new-form .button-group .btn {
  width: 100%;
  max-width: 400px;
}
body.new-form .help-block {
  margin-top: 20px;
  font-size: 12px;
}
body.school_index .form-name {
  color: #a9a9a9;
}
body.school_index .well h3 {
  margin-top: 10px;
}
body.school_index div + h2 {
  margin-top: 60px;
}
body.school_index .questionnaires .well {
  background: white;
}
body.school_index .questionnaires .well.green {
  border-color: #14945f;
}
body.school_index .questionnaires .well .section {
  border: none;
  border-bottom: 3px solid #f5f5f5;
  box-shadow: none;
  margin: 0;
  padding: 20px 0;
}
body.school_index .questionnaires .well .section:last-child {
  border: none;
}
body.school_index .questionnaires .well h4 {
  font-size: 1.4rem;
}
body.school_index .section {
  display: flex;
  flex-wrap: wrap;
}
body.school_index .section h4 {
  margin: 0px;
  line-height: 32px;
}
body.school_index .section .main {
  flex: 3;
}
body.school_index .section .action {
  justify-self: right;
  align-self: center;
}
body.school_index .section .action .btn {
  margin-left: 5px;
  margin-bottom: 5px;
}
body.school_index .section .no-results {
  color: #a9a9a9;
}
body.school_index #invite-admin .glyphicon,
body.school_index #create-new-questionnaire .glyphicon {
  margin-left: 10px;
}
body.school_index .admins ul {
  padding-left: 0;
  list-style: none;
}
body.school_index .admins ul li {
  margin: 10px 0;
}
body.school_index .admins #invite-admin {
  margin-top: 30px;
}
body.school_index .btn-toolbar form {
  margin-left: 5px;
  display: inline-block;
}
body.codes a.btn.disabled {
  cursor: not-allowed;
  pointer-events: auto;
}
body.codes .section {
  display: flex;
  flex-wrap: wrap;
}
body.codes .section .main {
  flex: 3;
}
body.codes .section .action {
  justify-self: right;
  align-self: center;
}
body.codes .section .action .btn {
  margin-left: 5px;
  margin-bottom: 5px;
}
body.codes .section .no-results {
  color: #a9a9a9;
}
body.codes #codes-container {
  margin-bottom: 30px;
}
body.results .app-header .btn.btn-underline {
  color: black;
}
body.results .app-header .btn.btn-underline:before {
  background: black;
}
body.results .app-header .btn.btn-underline:hover,
body.results .app-header .btn.btn-underline:active {
  color: white;
  background: none;
}
body.results .school-listing .school {
  margin: 0 0 40px;
}
body.results .open-forms {
  list-style: none;
  padding: 0;
}
body.results .ongoing-form {
  border-color: #942116;
}
body.results .school li {
  display: flex;
}
body.results .school li .info {
  flex: 1;
  align-self: center;
}
body.results .school li .info .name {
  color: #777;
  padding-left: 10px;
}
body.results .school li .actions {
  flex-basis: 100px;
  align-self: center;
  text-align: end;
}
body.results .city-result {
  margin: 40px 0;
}
body.report h3 {
  font-size: 24px;
}
body.report h4 {
  font-size: 18px;
  text-transform: uppercase;
}
body.report .app-header .btn.btn-underline {
  color: black;
}
body.report .app-header .btn.btn-underline:before {
  background: black;
}
body.report .app-header .btn.btn-underline:hover,
body.report .app-header .btn.btn-underline:active {
  color: white;
  background: none;
}
body.report .toolbar {
  display: flex;
  padding: 30px 0;
  border-bottom: 1px solid #f5f5f5;
}
body.report .toolbar label {
  flex: 8;
}
body.report .toolbar label span {
  line-height: 33px;
  font-size: 18px;
  font-weight: 100;
  margin: 0 20px 0 0;
  display: inline-block;
}
body.report .toolbar label select {
  display: inline-block;
  width: auto;
}
body.report .toolbar .button-container {
  flex: 4;
  text-align: right;
}
@media screen and (max-width: 500px) {
  body.report .toolbar {
    display: block;
  }
  body.report .toolbar .button-container {
    text-align: left;
    margin-top: 10px;
  }
}
body.report header {
  overflow: hidden;
  margin: 20px 0;
}
body.report header h2,
body.report header div {
  float: left;
}
body.report header div {
  margin-left: 20px;
  padding-top: 18px;
}
body.report .fetching-data-bar {
  margin: 80px auto;
  max-width: 600px;
}
body.report .basic-results,
body.report .category {
  margin-left: -15px;
  margin-right: -15px;
}
body.report .basic-results > section,
body.report .category > section {
  margin: 20px 0;
}
body.report .basic-results > section > .row,
body.report .category > section > .row {
  margin: 15px 0;
}
body.report .basic-results > section > .row .expandable-graph,
body.report .category > section > .row .expandable-graph {
  position: relative;
  cursor: pointer;
  border: 1px solid #f5f5f5;
}
body.report .basic-results > section > .row .expandable-graph:hover:after,
body.report .category > section > .row .expandable-graph:hover:after {
  opacity: 1;
}
body.report .basic-results > section > .row .expandable-graph:after,
body.report .category > section > .row .expandable-graph:after {
  content: "+";
  opacity: 0.6;
  position: absolute;
  text-align: center;
  line-height: 30px;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: black;
  color: white;
  font-size: 28px;
  border-radius: 0 0 0 3px;
}
body.report .basic-results > section > .row .expandable-graph.expanded,
body.report .category > section > .row .expandable-graph.expanded {
  width: 100%;
  max-height: none;
}
body.report .basic-results > section > .row .expandable-graph.expanded .minimize,
body.report .category > section > .row .expandable-graph.expanded .minimize {
  display: block;
}
body.report .basic-results > section > .row .expandable-graph.expanded .expand,
body.report .category > section > .row .expandable-graph.expanded .expand {
  display: none;
}
body.report .basic-results > section > .row .expandable-graph.expanded:after,
body.report .category > section > .row .expandable-graph.expanded:after {
  content: "-";
  line-height: 27px;
}
body.report .basic-results > section > .row .graph .minimize,
body.report .category > section > .row .graph .minimize {
  display: none;
}
body.report .basic-results .highlighted-text span,
body.report .category .highlighted-text span {
  color: #158cba;
  font-weight: bold;
  display: inline-block;
  font-size: 110%;
}
body.report .basic-results .highlighted-text span.capitalize,
body.report .category .highlighted-text span.capitalize {
  text-transform: capitalize;
}
body.report .basic-results .highlighted-text i,
body.report .category .highlighted-text i {
  font-style: normal;
}
body.report .basic-results .highlighted-text i:after,
body.report .category .highlighted-text i:after {
  content: "\A0";
}
body.report .basic-results .highlighted-text p:last-child,
body.report .category .highlighted-text p:last-child {
  margin-bottom: 0;
}
body.report .basic-results .highlighted-text.big,
body.report .category .highlighted-text.big {
  font-size: 140%;
}
body.report .basic-results .highlighted-text.huge,
body.report .category .highlighted-text.huge {
  font-size: 200%;
}
body.report .basic-results .accordion-toggle,
body.report .category .accordion-toggle {
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
}
body.report .area-overview {
  margin: 40px 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
}
body.report .area-overview h3,
body.report .area-overview p {
  margin: 30px 15px 15px;
}
body.report .area-overview h3 + p {
  margin-top: -5px;
}
body.report .area-overview h4 {
  margin: 45px 15px 15px;
}
body.report .area-overview h4.line-graph-heading {
  margin-bottom: 35px;
  margin-top: 25px;
}
body.report .area-overview header {
  display: flex;
  align-items: center;
}
body.report .area-overview header h3 {
  flex: 1;
}
body.report .area-overview header button {
  flex-basis: 100px;
  background: transparent;
}
body.report .area-overview .question {
  margin: 15px 0;
  padding: 0 15px;
}
body.report .area-overview .area-breakdown {
  padding: 15px 0;
  border-bottom: 1px solid #f5f5f5;
}
body.report .area-overview .area-breakdown .area-breakdown__text {
  display: flex;
}
body.report .area-overview .area-breakdown .area-breakdown__text h4 {
  flex: 2;
}
body.report .area-overview .area-breakdown .area-breakdown__text p {
  flex: 4;
  text-align: right;
  margin: 45px 15px 15px;
}
body.report .area-overview .area-breakdown .area-breakdown__graph {
  margin: 15px;
}
body.report .category {
  margin-top: 40px;
}
body.report .category h2 {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f5f5f5;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
}
body.report .category .subcategory {
  margin: -15px;
  border: 20px solid transparent;
}
body.report .category .subcategory.odd {
  background: #f5f5f5;
  border-color: #f5f5f5;
}
body.report .category .question {
  display: flex;
  margin-bottom: 15px;
}
body.report .category .question .text {
  flex: 3;
  align-self: center;
  font-size: 100%;
}
body.report .category .question .answer-sources {
  flex: 9;
}
body.report .category .question .answer-sources .answer-source:nth-child(even) {
  margin-top: 15px;
  border-top: 1px solid #dadada;
  padding-top: 15px;
}
body.report .category .question:nth-child(even) {
  background: #f5f5f5;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}
body.report .category .answer-source {
  display: flex;
}
body.report .category .answer-source .answer-source__name {
  flex: 2;
  align-self: center;
  font-size: 80%;
  text-transform: uppercase;
}
body.report .category .answer-source .answer-source__rows {
  flex: 12;
}
body.report .category .answer-row {
  display: flex;
  flex: 7;
  margin: 5px 0;
  max-width: 100%;
}
body.report .category .answer-row .name,
body.report .category .answer-row .amount {
  font-size: 80%;
  align-self: center;
  margin: 0 10px;
}
body.report .category .answer-row .name {
  flex: 2;
  text-align: right;
}
body.report .category .answer-row .amount {
  flex: 1;
  text-align: right;
}
body.report .category .answer-row .graph,
body.report .category .answer-row .no-results {
  align-self: center;
  flex: 14;
}
body.report .category .answer-row .no-results {
  font-size: 80%;
  text-align: center;
  background: #cfe2e9;
  line-height: 40px;
}
body.report .category .answer-row .freetext-container {
  align-self: center;
  flex: 14;
  max-width: 100%;
}
body.report .category .answer-row .freetext-container .header {
  text-align: right;
}
body.report .category .answer-row .freetext-container .header .highlighted-text {
  margin-bottom: 10px;
}
body.report .category .answer-row .freetext-container .freetext {
  padding: 10px;
  border-bottom: 1px solid transparent;
  line-break: anywhere;
}
body.report .category .answer-row .freetext-container .freetext.odd {
  background: #f5f5f5;
}
body.report .category .answer-row .freetext-container .freetext.even {
  background: white;
}
body.report .category .answer-row .freetext-container .freetext:hover {
  border-bottom-color: #a9a9a9;
}
body.report .category .plot_title {
  display: none;
}
body.report .line-graph {
  position: relative;
  top: -65px;
}
table {
  width: 100%;
}
.sharing-modal table tbody tr {
  border-bottom: 1px solid #f5f5f5;
}
.sharing-modal table .shared-link-text {
  font-size: 10px;
  width: 215px;
  border-style: solid;
}
.sharing-modal table .btn {
  margin: 5px 10px;
  transition: 200ms linear all;
}
.sharing-modal table .btn.copy-button {
  background: none;
}
.sharing-modal table .btn.copy-button.copied {
  background: #14945f;
}
.accordion {
  padding: 15px 0;
}
.accordion .toggle {
  display: block;
  margin: 15px auto;
}
.accordion .toggle .glyphicon {
  margin-left: 10px;
}
.accordion.open .toggle {
  background-color: #e2e2e2;
  border-color: #eeeeee;
}
.semester-dropdown-and-button {
  display: flex;
}
.semester-dropdown-and-button select {
  margin-right: 10px;
}
.form-title {
  margin: 10px 0;
}
/* form description */
.form-title span {
  line-height: 22px;
  font-size: 10px;
  margin: 0px 0px 0px 16px;
  font-style: italic;
  display: inline-block;
}
.panel {
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  margin-bottom: 1em;
  margin-top: 0.5em;
  text-align: center;
  vertical-align: middle;
  padding-bottom: 1em;
  font-size: 0.8em;
}
/* TODO.md: needs to be recalculated. I've grown the width of group-box to fit long stuff like
 * "fourth(411)". this is a localization hazard with languages that have long terms. 
 * should perhaps be divided into two eiements with set widths and overflow hidden..  
 */
.group-icon {
  width: 18px;
  height: 18px;
  float: left;
  padding: 0;
  margin: 0;
}
.group-text {
  text-align: left;
  width: 60px;
}
.group-text img {
  float: left;
}
.group-text span {
  float: right;
  font-weight: bold;
  font-size: 0.8em;
}
.claim {
  width: 120px;
  font-size: 0.95em;
  float: left;
  font-variant: small-caps;
  text-align: center;
  color: #FFFFFF;
}
.bar-topic {
  float: left;
  text-align: center;
  color: #777;
  overflow: hidden;
  padding: 0;
  margin: 0px 1px 2px 0px;
  height: 3.5em;
}
.bar-indicator {
  width: 100%;
  height: 4px;
  margin-top: 4px;
}
.btn.btn-underline {
  color: white;
  text-transform: uppercase;
  padding: 7px 7px 5px 7px;
  font-size: 13px;
  font-weight: 700;
  border-radius: 0;
  position: relative;
  z-index: 2;
  -webkit-transition: color 200ms ease-out 50ms;
  -moz-transition: color 200ms ease-out 50ms;
  -ms-transition: color 200ms ease-out 50ms;
  -o-transition: color 200ms ease-out 50ms;
  transition: color 200ms ease-out 50ms;
}
.btn.btn-underline.black {
  color: black;
}
.btn.btn-underline.black:before {
  background: black;
}
.btn.btn-underline:before {
  z-index: -1;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
  background: white;
  display: block;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -ms-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.btn.btn-underline:hover,
.btn.btn-underline:active {
  color: black;
  background: none;
}
.btn.btn-underline:hover:before,
.btn.btn-underline:active:before {
  height: 29px;
}
.btn.btn-underline:hover.black,
.btn.btn-underline:active.black {
  color: white;
}
@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  .form-control-panel,
  .breadcrumb,
  .nav-tabs,
  .app-footer {
    display: none;
  }
  .main-content .tab-content {
    border-top: 0;
  }
  .choice-distribution .bar,
  .question-header .choice,
  .agree-disagree .bar {
    border-radius: 0;
  }
  .choice-distribution .bar.empty,
  .question-header .choice.empty,
  .agree-disagree .bar.empty {
    display: none;
  }
  .diagram-widget .expanded-info {
    display: none !important;
  }
  .diagram-widget.expanded .expanded-info {
    display: block !important;
  }
  .diagram-widget.expanded .bar {
    border-left: 1px solid black;
  }
  .report .area-overview {
    page-break-before: always;
    box-shadow: none;
  }
  .report .area-overview:first-child {
    page-break-before: avoid;
  }
  .freetext-container > .header > button,
  .accordion > button {
    display: none !important;
  }
  .agree-disagree .bar {
    color: white !important;
  }
  .agree-disagree .bar1 {
    background-color: #e03537 !important;
  }
  .agree-disagree .bar2 {
    background-color: #e35d31 !important;
  }
  .agree-disagree .bar3 {
    background-color: #ebd243 !important;
  }
  .agree-disagree .bar4 {
    background-color: #85b63c !important;
  }
  .agree-disagree .bar5 {
    background-color: #14945f !important;
  }
  .agree-disagree .bars.reversed .bar1 {
    background-color: #14945f !important;
  }
  .agree-disagree .bars.reversed .bar2 {
    background-color: #85b63c !important;
  }
  .agree-disagree .bars.reversed .bar3 {
    background-color: #ebd243 !important;
  }
  .agree-disagree .bars.reversed .bar4 {
    background-color: #e35d31 !important;
  }
  .agree-disagree .bars.reversed .bar5 {
    background-color: #e03537 !important;
  }
  .choice-distribution .bar {
    color: white !important;
    background-color: #777 !important;
  }
  .choice-distribution .bar.odd {
    color: white !important;
    background-color: #555 !important;
  }
  .expand-button {
    display: none;
  }
  .area-topic .area-name {
    font-size: 28px;
    text-transform: uppercase;
    white-space: nowrap;
  }
}
.invitation .main-content form {
  padding: 20px 0;
}
body.manage-admins form p:first-child {
  margin: 15px 0 30px;
}
body.manage-admins ul.admins {
  padding-left: 0;
}
body.manage-admins ul.admins li {
  display: flex;
  margin: 10px 0;
  padding: 10px 5px;
}
body.manage-admins ul.admins li div:first-child {
  flex: 4;
}
body.manage-admins ul.admins li div:last-child {
  flex: 1;
  text-align: right;
}
body.manage-admins ul.admins li:hover {
  background: #f5f5f5;
}
body.instructions {
  color: black;
}
body.instructions .messages {
  position: absolute;
  top: 60px;
  width: 100%;
}
body.instructions .messages .alert {
  text-align: center;
}
body.instructions .messages .alert.alert-success {
  background: rgba(40, 182, 44, 0.6);
}
body.instructions .breadcrumb {
  display: none;
}
body.instructions .navbar.app-header .navbar-toggle {
  color: white;
}
body.instructions .navbar.app-header .btn-underline {
  color: white;
}
body.instructions .navbar.app-header .btn-underline:before {
  background: white;
}
body.instructions .navbar.app-header .btn-underline:hover,
body.instructions .navbar.app-header .btn-underline:active {
  color: black;
}
@media screen and (max-width: 767px) {
  body.instructions .navbar.app-header h1 {
    font-size: 30px;
  }
  body.instructions .navbar.app-header .navbar-collapse .btn-underline {
    color: black;
  }
}
body.instructions .navbar.app-header .navbar-brand img.light {
  display: block;
}
body.instructions .navbar.app-header .navbar-brand img.dark {
  display: none;
}
body.instructions .hero-image {
  background-image: url('/public/images/Khp_header2.jpg');
}
body.instructions h2 {
  margin: 0 0 15px 0;
}
body.instructions ol {
  padding-left: 20px;
}
body.instructions .col-md-4 img {
  margin-top: 10px;
  max-width: 220px;
}
body.instructions .green {
  background: #cdeadf;
}
body.instructions .white {
  background: white;
}
body.instructions .blue {
  background: #cfe2e9;
}
body.instructions .green .text ol,
body.instructions .white .text ol,
body.instructions .blue .text ol {
  float: left;
}
body.instructions .green .text ol li,
body.instructions .white .text ol li,
body.instructions .blue .text ol li {
  margin-top: 2rem;
  break-inside: avoid;
}
body.instructions .green .text ol li:first-child,
body.instructions .white .text ol li:first-child,
body.instructions .blue .text ol li:first-child {
  margin-top: 0;
}
body.instructions .green .text .with-images,
body.instructions .white .text .with-images,
body.instructions .blue .text .with-images {
  list-style: none;
}
body.instructions .green .text .with-images > *,
body.instructions .white .text .with-images > *,
body.instructions .blue .text .with-images > * {
  display: flex;
  gap: 0;
  padding: 30px 0;
}
body.instructions .green .text .with-images > * .image-container,
body.instructions .white .text .with-images > * .image-container,
body.instructions .blue .text .with-images > * .image-container {
  flex: 4;
  align-self: center;
}
body.instructions .green .text .with-images > * .image-container img,
body.instructions .white .text .with-images > * .image-container img,
body.instructions .blue .text .with-images > * .image-container img {
  max-width: 220px;
  border: 10px solid #f5f5f5;
}
body.instructions .green .text .with-images > * .explanation,
body.instructions .white .text .with-images > * .explanation,
body.instructions .blue .text .with-images > * .explanation {
  flex: 8;
  align-self: center;
}
body.instructions .green .text .with-images > *:nth-child(2n),
body.instructions .white .text .with-images > *:nth-child(2n),
body.instructions .blue .text .with-images > *:nth-child(2n) {
  background: #f5f5f5;
}
@media screen and (max-width: 480px) {
  body.instructions .green .text .with-images > *,
  body.instructions .white .text .with-images > *,
  body.instructions .blue .text .with-images > * {
    display: block;
  }
  body.instructions .green .text .with-images > * .explanation,
  body.instructions .white .text .with-images > * .explanation,
  body.instructions .blue .text .with-images > * .explanation {
    margin-top: 15px;
    margin-bottom: 60px;
  }
  body.instructions .green .text .with-images > * .image-container img,
  body.instructions .white .text .with-images > * .image-container img,
  body.instructions .blue .text .with-images > * .image-container img {
    max-width: 300px;
  }
}
body.instructions .green .text.two-columns,
body.instructions .white .text.two-columns,
body.instructions .blue .text.two-columns {
  -webkit-columns: auto 2;
  -moz-columns: auto 2;
  columns: auto 2;
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px;
}
@media screen and (max-width: 480px) {
  body.instructions .green .text.two-columns,
  body.instructions .white .text.two-columns,
  body.instructions .blue .text.two-columns {
    -webkit-columns: auto 1;
    -moz-columns: auto 1;
    columns: auto 1;
  }
}
@media screen and (max-width: 768px) {
  body.instructions .green h2,
  body.instructions .white h2,
  body.instructions .blue h2 {
    text-align: center;
    padding-top: 20px;
  }
}
body.instructions .blue .text .with-images > * .image-container img {
  border-color: transparent;
}
body.sharing .navbar-brand {
  height: auto;
  position: relative;
  padding-bottom: 0;
  left: -43px;
}
body.sharing .navbar-brand h1 {
  display: inline-block;
  margin-left: 30px;
  position: relative;
  top: 8px;
}
body.sharing .language-chooser {
  margin: 46px 0;
}
body.sharing .language-chooser a {
  display: inline-block;
  margin: 0 0 0 15px;
  text-transform: uppercase;
  color: #2f2f2f;
}
body.sharing .language-chooser a.active {
  text-decoration: underline;
}
body.sharing .page-title {
  margin-left: 30px;
}
body.sharing .sharing-specs {
  margin-left: -10px;
  font-size: 140%;
  list-style: none;
}
body {
  position: relative;
  min-height: 85vh;
  font-family: 'Open Sans', sans-serif;
}
h1,
h2 {
  font-family: 'Carter One', cursive;
}
h1,
h2,
h3 {
  text-transform: uppercase;
}
@media screen and (max-width: 400px) {
  .container h2 {
    font-size: 24px;
  }
}
h3 {
  font-size: 18px;
  font-weight: bold;
}
.vertical-spacing {
  margin-top: 60px;
  margin-bottom: 60px;
}
.vertical-spacing-bottom {
  margin-bottom: 60px;
}
.vertical-spacing-top {
  margin-top: 60px;
}
.vertical-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}
.tiny-line-hr {
  width: 100%;
  max-width: 105px;
  height: 0;
  border-top: 2px solid white;
  margin: 30px 0;
  display: inline-block;
}
.tiny-line-hr.black {
  border-top: 2px solid black;
}
.app-header.navbar .navbar-brand {
  padding-top: 10px;
  padding-left: 20px;
}
.app-header.navbar .navbar-brand img {
  display: inline-block;
  width: 29px;
}
.app-header.navbar .navbar-brand img.light {
  display: none;
}
.app-header.navbar .navbar-brand span {
  display: none;
}
.app-header.navbar .navbar-toggle {
  color: black;
}
@media screen and (max-width: 767px) {
  .app-header.navbar .navbar-collapse {
    background: white;
    position: absolute;
    top: 50px;
    right: 15px;
    width: 100%;
    border: 1px solid #f5f5f5;
  }
  .app-header.navbar .navbar-collapse .btn-underline {
    color: black;
  }
}
.app-header.navbar .nav > li {
  margin-left: 15px;
}
.app-header.navbar .btn-underline {
  color: black;
}
.app-header.navbar .btn-underline:before {
  background: black;
}
.app-header.navbar .btn-underline:hover,
.app-header.navbar .btn-underline:active {
  color: white;
}
.app-header.navbar .language-choice select {
  height: 30px;
  line-height: 20px;
  padding: 2px 20px 2px 12px;
  font-size: 12px;
  background: url(/public/images/dropdown_icon.png) right / 20px no-repeat rgba(230, 230, 230, 0.3);
  border: 0;
  color: #2f2f2f;
}
.app-header.navbar .language-choice select option {
  color: black;
}
.container.messages {
  padding-top: 30px;
}
body.data_protection .app-header.navbar .language-choice select,
body.pricing .app-header.navbar .language-choice select,
body.about .app-header.navbar .language-choice select,
body.frontpage .app-header.navbar .language-choice select {
  color: white;
  background-color: rgba(255, 255, 255, 0.3);
  background-image: url(/public/images/dropdown_icon_white.png);
}
.breadcrumb {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 0;
  padding-left: 2px;
}
.main-content {
  min-height: 500px;
}
.app-footer {
  width: 100%;
  background: #1d2e3a;
  color: white;
  margin: 0;
  padding-top: 60px;
  padding-bottom: 60px;
}
.app-footer ul {
  text-align: center;
}
.app-footer ul li {
  padding: 5px 0;
}
.app-footer ul li a {
  text-transform: uppercase;
  font-weight: 700;
  color: white;
}
.app-footer ul li.spacer {
  margin-top: 20px;
}
.app-footer .kokoa-cert {
  display: block;
  text-align: center;
}
.form-group.odd {
  background: #f5f5f5;
  margin-bottom: 0;
}
.form-group.even {
  background: white;
  margin-bottom: 0;
}
#loader {
  padding-top: 30px;
  padding-bottom: 30px;
}
#no-results-notification {
  padding-top: 30px;
  padding-bottom: 30px;
}
.bars {
  width: 100%;
  background: #f5f5f5;
}
.bars .bar-box {
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.bars .bar-box .bar {
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: 20px;
  line-height: 20px;
}
.form-control-panel {
  margin-bottom: 20px;
}
.form-control-panel .radio.with-select input {
  display: inline;
  margin-top: 11px;
}
.form-control-panel .radio.with-select select {
  display: inline;
  width: auto;
  margin-left: 10px;
}
.form-control-panel label.disabled {
  color: #a9a9a9;
}
.form-control-panel .selected-comparisons label {
  margin: 5px;
  background: #cdeadf;
  border-radius: 5px;
  padding: 3px 2px 4px 5px;
}
.form-control-panel .selected-comparisons label button {
  margin-left: 3px;
  background: none;
  border: none;
}
.form-control-panel .submit .align-to-right {
  overflow: hidden;
  text-align: right;
}
.form-control-panel .submit .align-to-right > * {
  display: inline-block;
  margin-left: 10px;
}
.form-control-panel .submit > .btn {
  margin-top: 60px;
}
.form-control-panel .submit .btn-toolbar {
  margin-top: 40px;
}
.form-control-panel .submit .btn-toolbar > .btn {
  margin-right: 10px;
}
.form-control-panel .submit .btn-toolbar .export-button {
  padding-bottom: 5px;
}
.export-buttons .glyphicon {
  font-size: 15px;
}
.export-buttons .export-button {
  margin-right: 5px;
}
.export-buttons .export-button .xls-emblem {
  background: url('/public/images/excelicon_small.gif') no-repeat left top;
  width: 17px;
  height: 17px;
  display: inline-block;
}
.export-buttons .export-button .csv-emblem {
  background: url('/public/images/csvicon_small.gif') no-repeat left top;
  width: 17px;
  height: 17px;
  display: inline-block;
}
.header-container {
  border-bottom: 1px solid white;
  background: #f5f5f5;
  color: #2f2f2f;
  overflow: hidden;
  padding: 15px;
  cursor: pointer;
}
.header-container span.glyphicon {
  display: block;
  margin: 10px auto;
}
.header-container .expand-button,
.header-container .area-name {
  display: inline-block;
}
.header-container .expand-button {
  width: 20%;
  float: left;
  text-align: center;
}
.header-container .area-name {
  width: 80%;
  padding-top: 5px;
}
.header-container .answer,
.form-answers .answer {
  padding: 15px 15px 15px 65px;
  position: relative;
  overflow: hidden;
}
.header-container .answer .groups,
.form-answers .answer .groups {
  position: absolute;
  left: 15px;
  top: 47px;
}
.nav-tabs {
  border-bottom: 0;
}
.nav-tabs #tabs-preview {
  color: #158cba;
}
.nav-tabs #tabs-preview span {
  margin-left: 10px;
}
.tab-content {
  border-top: 3px solid #f5f5f5;
  padding-bottom: 60px;
}
.diagram-widget.choice-distribution {
  overflow: hidden;
  cursor: pointer;
}
.diagram-widget.choice-distribution .bar {
  background: #777;
  line-height: 40px;
  text-align: center;
  color: #f5f5f5;
  height: 40px;
  float: left;
  font-size: 11px;
}
.diagram-widget.choice-distribution .bar.odd {
  background: #555;
}
.diagram-widget.choice-distribution .bar.empty {
  border-right: 0;
}
.diagram-widget.agree-disagree {
  cursor: pointer;
}
.diagram-widget.agree-disagree .bar {
  border-right-color: white;
  line-height: 40px;
  text-align: center;
  color: #f5f5f5;
  height: 40px;
  float: left;
  font-size: 11px;
}
.diagram-widget.agree-disagree .bar1 {
  background-color: #e03537;
}
.diagram-widget.agree-disagree .bar2 {
  background-color: #e35d31;
}
.diagram-widget.agree-disagree .bar3 {
  background-color: #ebd243;
}
.diagram-widget.agree-disagree .bar4 {
  background-color: #85b63c;
}
.diagram-widget.agree-disagree .bar5 {
  background-color: #14945f;
}
.diagram-widget.agree-disagree .bars.reversed .bar1 {
  background-color: #14945f;
}
.diagram-widget.agree-disagree .bars.reversed .bar2 {
  background-color: #85b63c;
}
.diagram-widget.agree-disagree .bars.reversed .bar3 {
  background-color: #ebd243;
}
.diagram-widget.agree-disagree .bars.reversed .bar4 {
  background-color: #e35d31;
}
.diagram-widget.agree-disagree .bars.reversed .bar5 {
  background-color: #e03537;
}
.diagram-widget .bar {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  white-space: nowrap;
}
.diagram-widget.expanded {
  height: auto;
  overflow: visible;
  margin-bottom: 20px;
}
.diagram-widget.expanded .bar {
  display: block;
  float: none;
  font-size: 0;
  color: transparent;
}
.diagram-widget.expanded .bar .expanded-info {
  font-size: 11px;
  color: #555;
  float: right;
  width: 100px;
  margin-right: -100px;
  padding-left: 5px;
}
.diagram-widget.expanded .bar.empty {
  display: none;
}
.row-even {
  background-color: #cdeadf;
}
.row-odd {
  background-color: white;
}
.row-area {
  background-color: #cdeadf;
}
.tab-content.codes {
  padding-top: 15px;
}
.tab-content.codes #codes-container {
  overflow: hidden;
}
.tab-content.codes .btn-toolbar {
  margin-top: 5px;
}
.tab-content.codes .well .glyphicon {
  font-size: 60px;
  margin: 15px auto;
}
.tab-content.codes .code-box-container {
  width: 25%;
  min-width: 140px;
  float: left;
  padding: 2px;
}
.tab-content.codes .code-box-container .code-box {
  height: 120px;
  padding-top: 15px;
  text-align: center;
  border: 1px dotted black;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.tab-content.codes .code-box-container .code-box span {
  font-size: 60%;
}
.tab-content.codes .code-box-container .code-box .code {
  font-size: 250%;
  color: #777;
}
.progress-bar {
  -webkit-transition-duration: 5ms;
  -moz-transition-duration: 5ms;
  -ms-transition-duration: 5ms;
  -o-transition-duration: 5ms;
  transition-duration: 5ms;
}
.pdf-emblem {
  background: url('/public/images/pdficon_small.gif') no-repeat left top;
  width: 17px;
  height: 17px;
  display: inline-block;
  position: relative;
  left: -4px;
  top: 0px;
  float: left;
}
.well {
  overflow: hidden;
}
.well > a {
  font-size: 16px;
  display: inline-block;
  padding-top: 2px;
}
.errorlist {
  color: #e03537;
  font-weight: bold;
  list-style: none;
  padding: 0;
}
.errorlist.nonfield {
  padding-bottom: 20px;
}
form p + .errorlist {
  margin-top: 20px;
}
form span.helptext {
  font-size: 80%;
}
form .confirmed-field {
  position: relative;
}
form .confirmed-field input {
  display: inline-block;
}
form .confirmed-field .glyphicon {
  position: absolute;
  right: 15px;
  top: 12px;
  z-index: 1;
}
.main-content .btn {
  font-weight: normal;
  letter-spacing: 0.1rem;
}
.semester-chooser .year {
  font-size: 1.1em;
  color: #2f2f2f;
}
.semester-chooser .semester {
  color: #333;
}
.school-summaries .school-summaries-list {
  display: flex;
  column-gap: 3%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
.school-summaries .school-summaries-list .school-summary {
  flex-basis: 31.33%;
  border-left: 10px solid #f5f5f5;
}
.school-summaries .school-summaries-list .school-summary.answers-in-current-year {
  border-color: #cdeadf;
}
.school-summaries .school-summaries-list .school-summary.answers-in-current-year.answers-in-current-semester {
  border-color: #14945f;
}
@media screen and (max-width: 1000px) {
  .school-summaries .school-summaries-list .school-summary {
    flex-basis: 48.5%;
  }
}
.card {
  box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
  padding: 15px 10px;
}
