.extra-questions-widget .question {
  padding: 3px 0 0;
}
.extra-questions-widget .question:last-child {
  padding-bottom: 7px;
}
.extra-questions-widget .question .btn-danger .glyphicon {
  margin-left: 0;
}
.extra-questions-widget .question .help-block {
  margin-top: 3px;
}
.extra-questions-widget .bottom-buttons {
  padding: 7px 0 15px;
  text-align: center;
}
.extra-questions-widget .bottom-buttons > .btn {
  display: inline-block;
}
